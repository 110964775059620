/**
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 *
 * To import entries where examples may show a tilde (~), add the package in angular.json.
 * ATOW it's e.g.
 *          "stylePreprocessorOptions": {
 *            "includePaths": [
 *              "./node_modules/swiper",
 *              "./node_modules/@ionic/angular/css"
 *            ]
 *          },
 *
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
// @import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
// @import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

app-root,
ion-app,
ion-router-outlet {
    // height: 600px;
    // width: 600px;
    // min-height: 500px;
    // min-width: 200px;
    // background-color: white;
    display: flex;

    /* allow text to be selected by default */
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    // box-sizing: border-box;
}

ion-skeleton-text {
    --background: var(--ion-color-primary);
    --background-rgb: var(--ion-color-primary-rgb);
}

ion-input.floating-with-placeholder {
    input:focus::-webkit-input-placeholder {
        color: gray;
    }

    input:focus:-moz-placeholder {
        color: gray;
    }

    input:focus::-moz-placeholder {
        color: gray;
    }

    input:focus:-ms-input-placeholder {
        color: gray;
    }

    input::-webkit-input-placeholder {
        color: transparent;
    }

    input:-moz-placeholder {
        color: transparent;
    }

    input::-moz-placeholder {
        color: transparent;
    }

    input:-ms-input-placeholder {
        color: transparent;
    }
}

// for swiper, customizing like ion-slides
// see https://ionicframework.com/docs/angular/slides
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/keyboard';
@import 'swiper/scss/zoom';
@import "swiper/scss/navigation";
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
// @import 'swiper/scss/effect-fade';
@import 'swiper/scss/effect-cube';
@import '@ionic/angular/css/ionic-swiper';

// this is required for navigation buttons in vertical swipers.
.swiper-container-vertical {

    .swiper-button-next,
    .swiper-button-prev {
        left: 50%;
        transform: rotate(90deg);
        transform-origin: left center;
        color: rgba(var(--ion-color-primary-rgb), 0.64);
    }

    .swiper-button-prev {
        top: 10px;
    }

    .swiper-button-next {
        top: auto;
        bottom: 10px;
    }

}


.pseudo-link {
    cursor: pointer;
    color: blue;

    &:hover {
        text-decoration: underline;
    }
}

@media (prefers-color-scheme: dark) {
    .pseudo-link {
        cursor: pointer;
        color: rgb(75, 75, 208);

        &:hover {
            text-decoration: underline;
        }
    }
}

// fullscreen modal related
@import './theme/size-variables.scss';

.fullscreen-modal {
    --height: 97%;
    --width: 97%;
    animation-name: grow-fullscreen;
    animation-duration: 1s;
}

@keyframes grow-fullscreen {
    0% {
        scale: 0.3
    }

    100% {
        scale: 1.0
    }
}

@media (min-height: $xs-height) and (max-height: $sm-height) {
    .fullscreen-modal {
        --height: 98%;
        --width: 98%;
    }
}

@media (min-height: $sm-height) and (max-height: $md-height) {
    .fullscreen-modal {
        --height: 98%;
        --width: 98%;
    }
}

@media (min-height: $md-height) and (max-height: $lg-height) {
    .fullscreen-modal {
        --height: 97%;
        --width: 97%;
    }
}

@media (min-height: $lg-height) and (max-height: $xl-height) {
    .fullscreen-modal {
        --height: 97%;
        --width: 97%;
    }
}

@media (min-height: $xl-height) {
    .fullscreen-modal {
        --height: 93%;
        --width: 93%;
    }
}
