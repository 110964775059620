// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #47a135;
  --ion-color-primary-rgb: 146, 237, 128;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #80d171;
  --ion-color-primary-tint: #9def8d;

  --ion-color-secondary: #53118e;
  --ion-color-secondary-rgb: 83, 17, 142;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #387a38;
  --ion-color-secondary-tint: #55a16a;

  --ion-color-tertiary: #48bd4f;
  --ion-color-tertiary-rgb: 72, 189, 79;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #3fa646;
  --ion-color-tertiary-tint: #5ac461;

  --ion-color-success: #15f16d;
  --ion-color-success-rgb: 21, 241, 109;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #12d460;
  --ion-color-success-tint: #2cf27c;

  --ion-color-warning: #ff22c7;
  --ion-color-warning-rgb: 255, 34, 199;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e01eaf;
  --ion-color-warning-tint: #ff38cd;

  --ion-color-danger: #ff1e28;
  --ion-color-danger-rgb: 255, 30, 40;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e01a23;
  --ion-color-danger-tint: #ff353e;

  --ion-color-dark: #481a56;
  --ion-color-dark-rgb: 72, 26, 86;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #3f174c;
  --ion-color-dark-tint: #5a3167;

  --ion-color-medium: #7b6a7b;
  --ion-color-medium-rgb: 123, 106, 123;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6c5d6c;
  --ion-color-medium-tint: #887988;

  --ion-color-light: #eafedd;
  --ion-color-light-rgb: 234, 254, 221;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cee0c2;
  --ion-color-light-tint: #ecfee0;
  --test-rgba-color: 240, 240, 240;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #92ed80;
    --ion-color-primary-rgb: 146, 237, 128;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #80d171;
    --ion-color-primary-tint: #9def8d;

    --ion-color-secondary: #8c49c7;
    --ion-color-secondary-rgb: 83, 17, 142;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #9bc93f;
    --ion-color-secondary-tint: #168855;

    --ion-color-tertiary: #48bd4f;
    --ion-color-tertiary-rgb: 72, 189, 79;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #3fa646;
    --ion-color-tertiary-tint: #5ac461;

    --ion-color-success: #15f16d;
    --ion-color-success-rgb: 21, 241, 109;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #12d460;
    --ion-color-success-tint: #2cf27c;

    --ion-color-warning: #ff22c7;
    --ion-color-warning-rgb: 255, 34, 199;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e01eaf;
    --ion-color-warning-tint: #ff38cd;

    --ion-color-danger: #ff1e28;
    --ion-color-danger-rgb: 255, 30, 40;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e01a23;
    --ion-color-danger-tint: #ff353e;

    --ion-color-dark: #481a56;
    --ion-color-dark-rgb: 72, 26, 86;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #3f174c;
    --ion-color-dark-tint: #5a3167;

    --ion-color-medium: #7b6a7b;
    --ion-color-medium-rgb: 123, 106, 123;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #6c5d6c;
    --ion-color-medium-tint: #887988;

    --ion-color-light: #eafedd;
    --ion-color-light-rgb: 234, 254, 221;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #cee0c2;
    --ion-color-light-tint: #ecfee0;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }
}

.body-container {
  width: 100%;
}
